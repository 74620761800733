.HeroText{
    font-size: 9rem;
    font-weight: 900;
    outline: none;
  background: linear-gradient(135deg, #5335cf 0%, #de005e 25%, #f66e48 50%, #de005e 75%, #5335cf 100%);
  background-size: 500%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  animation: animate 30s linear infinite;
}
h1{
  margin: 0;
  padding: 0;
}
@keyframes animate {
    to{
      background-position: 400%;
    }
  }