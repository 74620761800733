.App {
  

}
.App-header{
  padding-top: 2rem;

}

.Social{
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 2rem;
}


.Main{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Main >div{
  margin: 1.5rem;
}

.SubHeading{
  color: whitesmoke;
  font-size: 2rem;

}

.SubContent{
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: whitesmoke;
}
.SubContent h5{
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
}

section{
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.SkillSection{
  padding-top: 5rem;
  display: grid;
  row-gap: 1rem;
  column-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 70%;
  margin: auto;
}

